import React, {useState} from 'react';
import { graphql } from "gatsby";
import Header from "../components/Header/header";
import {ContainerL} from "../ui/containers";
import Heading from "../ui/heading";
import Card from "../components/Cards";
import Footer from "../components/Footer/footer";
import Modal from "../ui/modal";
import Seo from "../components/seo";
import "../components/layout.css";

const Cooperation = ({data}) => {
  const [openModal, setOpenModal] = useState(false);
  const fullData = data.allStrapiJoinWork.nodes[0];
  const {cards} = fullData;

  const cardsData = cards.map(item => {
    return ({
      title: item.title,
      text: item.description,
      imgUrl: item.image.localFile,

    })
  })

  const toggleOpenModal = () => {
    if (!openModal) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
    setOpenModal(!openModal);
  }
  return (
    <>
      <Seo title={'Сотрудничество'} />
      <Header/>
      {
        openModal ?
          (<Modal toggleOpenModal={toggleOpenModal}/>)
          :
          (
            <>
              <ContainerL style={{margin: '100px auto'}}>
                <Heading title={fullData.title}
                         description={fullData.description}
                         toggleOpenModal={toggleOpenModal}
                         background={"партнері"}
                />
              </ContainerL>

              <ContainerL style={{margin: "220px auto"}}>
                {cardsData.map((item, index) =>
                  <div key={index}>
                    <Card plus={false} toggleOpenModal={toggleOpenModal} isReversed={index % 2 !== 0} data={item} button/>
                  </div>
                )}
              </ContainerL>
              <Footer toggleOpenModal={toggleOpenModal}/>
            </>
          )}

    </>
  );
}

export default Cooperation;

export const query = graphql`
    query CooperationQuery {
        allStrapiJoinWork {
            nodes {
                description
                title
                cards {
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED)
                            }
                        }
                    }
                    description
                    title
                }
            }
        }
    }
`
